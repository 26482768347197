import React from 'react';
import { Container, Row, Col, Button, Card } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faLightbulb, faChartLine, faPuzzlePiece, faUsers, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { faLinkedin, faGithub } from '@fortawesome/free-brands-svg-icons';
import AboutMe from '../components/aboutMe.js';

const Home = () => {
  const [posts, setPosts] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState(null);

  React.useEffect(() => {
    const fetchPosts = async () => {
      try {
        const response = await fetch(
          'https://ghost.calebdunn.tech/ghost/api/v3/content/posts/?key=7b232bb2f33df162b138230396&fields=title,html,feature_image,published_at,url,excerpt'
        );
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        setPosts(data.posts);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchPosts();
  }, []);
  
  return (
    <>
      {/* Hero Section */}
      <section className="hero" id="home">
        <div className="hero-overlay"></div>
        <Container className="hero-content text-center">
          <h1 className="hero-title">Caleb Dunn</h1>
          <p className="hero-subtitle">Senior Product Manager specializing in Healthcare Informatics</p>
          <div className="hero-cta">
            <Button
              variant="outline-light"
              size="lg"
              className="me-3"
              href="#about"
            >
              Learn More
            </Button>
            <Button
              variant="primary"
              size="lg"
              href="/resume"
            >
              View Resume
            </Button>
          </div>
        </Container>
      </section>

      {/* About Section */}
      <AboutMe />

      {/* Expertise Section */}
      <section className="section bg-light" id="expertise">
        <Container>
          <h2 className="section-title">Areas of Expertise</h2>
          <Row className="g-4">
            <Col md={6} lg={3}>
              <Card className="h-100 border-0 shadow-sm">
                <Card.Body className="text-center p-4">
                  <div className="mb-4">
                    <FontAwesomeIcon icon={faLightbulb} size="3x" className="text-primary" />
                  </div>
                  <Card.Title>Product Strategy</Card.Title>
                  <Card.Text>
                    Developing comprehensive product strategies aligned with business goals and market needs.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col md={6} lg={3}>
              <Card className="h-100 border-0 shadow-sm">
                <Card.Body className="text-center p-4">
                  <div className="mb-4">
                    <FontAwesomeIcon icon={faChartLine} size="3x" className="text-primary" />
                  </div>
                  <Card.Title>Healthcare Informatics</Card.Title>
                  <Card.Text>
                    Leveraging healthcare data to drive product decisions and improve patient outcomes.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col md={6} lg={3}>
              <Card className="h-100 border-0 shadow-sm">
                <Card.Body className="text-center p-4">
                  <div className="mb-4">
                    <FontAwesomeIcon icon={faPuzzlePiece} size="3x" className="text-primary" />
                  </div>
                  <Card.Title>Product Development</Card.Title>
                  <Card.Text>
                    Guiding products from conception to launch with a focus on user experience and business value.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col md={6} lg={3}>
              <Card className="h-100 border-0 shadow-sm">
                <Card.Body className="text-center p-4">
                  <div className="mb-4">
                    <FontAwesomeIcon icon={faUsers} size="3x" className="text-primary" />
                  </div>
                  <Card.Title>Stakeholder Management</Card.Title>
                  <Card.Text>
                    Building consensus among diverse stakeholders to drive product success and adoption.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </section>

{/* Blog Posts Section */}
      <section className="section bg-white" id="blog">
        <Container>
          <h2 className="section-title">Latest Blog Posts</h2>
          <Row className="g-4">
            {loading ? (
              <p>Loading posts...</p>
            ) : error ? (
              <p>Error fetching posts: {error.message}</p>
            ) : (
              posts.map((post) => (
                <Col lg={4} key={post.url}>
                  <Card className="project-card border-0">
                    {post.feature_image && (
                      <Card.Img
                        variant="top"
                        src={post.feature_image}
                        className="project-image"
                      />
                    )}
                    <Card.Body>
                      <Card.Title>{post.title}</Card.Title>
                      <Card.Text>{post.excerpt}</Card.Text>
                      <Button
                        variant="outline-primary"
                        size="sm"
                        href={post.url}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Read More <FontAwesomeIcon icon={faArrowRight} />
                      </Button>
                    </Card.Body>
                  </Card>
                </Col>
              ))
            )}
          </Row>
        </Container>
      </section>

      {/* Contact Section */}
      <section className="section bg-primary text-white" id="contact">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6}>
              <div className="text-center mb-5">
                <h2 className="section-title text-white">Get In Touch</h2>
                <p className="lead">Interested in discussing how I can help your organization? Let's connect.</p>
              </div>
              <Card className="contact-form">
                <Card.Body>
                  <form>
                    <div className="form-group mb-3">
                      <label htmlFor="name" className="form-label">Name</label>
                      <input type="text" className="form-control" id="name" placeholder="Your Name" />
                    </div>
                    <div className="form-group mb-3">
                      <label htmlFor="email" className="form-label">Email</label>
                      <input type="email" className="form-control" id="email" placeholder="Your Email" />
                    </div>
                    <div className="form-group mb-3">
                      <label htmlFor="subject" className="form-label">Subject</label>
                      <input type="text" className="form-control" id="subject" placeholder="Subject" />
                    </div>
                    <div className="form-group mb-4">
                      <label htmlFor="message" className="form-label">Message</label>
                      <textarea className="form-control" id="message" rows="5" placeholder="Your Message"></textarea>
                    </div>
                    <div className="text-center">
                      <Button variant="primary" size="lg" className="w-100">Send Message</Button>
                    </div>
                  </form>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </section>

      {/* Footer */}
      <footer className="footer">
        <Container>
          <div className="footer-links">
            <a href="/" className="footer-link">Home</a>
            <a href="/#about" className="footer-link">About</a>
            <a href="/resume" className="footer-link">Resume</a>
            <a href="/#blog" className="footer-link">Blog Posts</a>
            <a href="/#contact" className="footer-link">Contact</a>
          </div>
          <div className="footer-social">
            <a href="https://linkedin.com/in/" target="_blank" rel="noopener noreferrer" className="social-icon">
              <FontAwesomeIcon icon={faLinkedin} />
            </a>
            <a href="https://github.com/" target="_blank" rel="noopener noreferrer" className="social-icon">
              <FontAwesomeIcon icon={faGithub} />
            </a>
            <a href="mailto:caleb.dunn@example.com" className="social-icon">
              <FontAwesomeIcon icon={faEnvelope} />
            </a>
          </div>
          <div className="footer-copyright">
            <p>&copy; {new Date().getFullYear()} Caleb Dunn. All rights reserved.</p>
          </div>
        </Container>
      </footer>
    </>
  );
};

export default Home;
