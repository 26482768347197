import React from 'react';
import { Container, Row, Col, Image, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import tulsa from '../images/tulsa.jpg';

const AboutMe = () => {
  return (
    <section className="about-section" id="about">
      <Container>
        <Row className="align-items-center">
          <Col lg={5} className="mb-5 mb-lg-0">
            <div className="about-image-container" data-aos="fade-right">
              <Image 
                src={tulsa} 
                className="about-image img-fluid" 
                alt="Caleb Dunn in Tulsa" 
              />
            </div>
          </Col>
          <Col lg={7} data-aos="fade-left">
            <div className="about-content">
              <h2 className="about-title">About Me</h2>
              <p className="about-text">
                I'm a Senior Product Manager with a unique background in clinical pharmacy and healthcare informatics. 
                This combination allows me to bridge the gap between healthcare providers and technology, creating 
                products that truly address the needs of the medical community.
              </p>
              <p className="about-text">
                Based in Tulsa, Oklahoma, I currently lead product strategy at DrFirst, where I'm responsible for 
                defining product vision, creating roadmaps, and prioritizing features that improve healthcare outcomes. 
                My experience spans from clinical practice to healthcare IT, giving me a comprehensive understanding 
                of the healthcare ecosystem.
              </p>
              <p className="about-text">
                When I'm not transforming healthcare through technology, you'll find me exploring the latest in frontend 
                JavaScript development, diving into data science with Python, or automating processes with Ansible. 
                I'm passionate about continuous learning and applying new technologies to solve complex problems.
              </p>
              <div className="mt-4 d-flex flex-wrap">
                <Button variant="primary" className="me-3 mb-3" href="/resume">
                  View My Resume <FontAwesomeIcon icon={faArrowRight} className="ms-2" />
                </Button>
                <Button variant="outline-primary" className="mb-3" href="/resume.pdf" target="_blank">
                  Download CV <FontAwesomeIcon icon={faDownload} className="ms-2" />
                </Button>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default AboutMe;
