import React, { useState, useEffect } from 'react';
import { Navbar, Nav, Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLinkedin, faGithub } from '@fortawesome/free-brands-svg-icons';
import { faFileAlt, faEnvelope } from '@fortawesome/free-solid-svg-icons';

const MyNavbar = () => {
  const [scrolled, setScrolled] = useState(false);
  
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };
    
    window.addEventListener('scroll', handleScroll);
    
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <Navbar 
      fixed="top" 
      bg={scrolled ? "dark" : "transparent"} 
      variant="dark" 
      expand="lg"
      className={`py-3 ${scrolled ? 'shadow' : ''}`}
      style={{ 
        transition: 'all 0.3s ease',
        backgroundColor: scrolled ? 'var(--dark)' : 'rgba(26, 37, 47, 0.9)'
      }}
    >
      <Container>
        <Navbar.Brand 
          as={Link} 
          to="/"
          className="fw-bold"
          style={{ 
            fontSize: '1.5rem', 
            fontFamily: 'var(--font-heading)',
            letterSpacing: '1px'
          }}
        >
          Caleb Dunn
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="navbar-nav" />
        <Navbar.Collapse id="navbar-nav">
          <Nav className="ms-auto">
            <Nav.Link as={Link} to="/" className="navbar-link mx-2">Home</Nav.Link>
            <Nav.Link href="/#about" className="navbar-link mx-2">About</Nav.Link>
            <Nav.Link as={Link} to="/resume" className="navbar-link mx-2">Resume</Nav.Link>
            <Nav.Link href="/#blog" className="navbar-link mx-2">Blog Posts</Nav.Link>
            <Nav.Link href="/#contact" className="navbar-link mx-2">Contact</Nav.Link>
          </Nav>
          <Nav className="ms-lg-4">
            <Nav.Link 
              href="https://linkedin.com/in/" 
              target="_blank" 
              rel="noopener noreferrer"
              className="social-nav-link"
            >
              <FontAwesomeIcon icon={faLinkedin} />
            </Nav.Link>
            <Nav.Link 
              href="https://github.com/" 
              target="_blank" 
              rel="noopener noreferrer"
              className="social-nav-link"
            >
              <FontAwesomeIcon icon={faGithub} />
            </Nav.Link>
            <Nav.Link 
              href="/resume.pdf" 
              target="_blank" 
              rel="noopener noreferrer"
              className="social-nav-link"
              title="Download Resume"
            >
              <FontAwesomeIcon icon={faFileAlt} />
            </Nav.Link>
            <Nav.Link 
              href="mailto:caleb.dunn@example.com" 
              className="social-nav-link"
              title="Email Me"
            >
              <FontAwesomeIcon icon={faEnvelope} />
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default MyNavbar;
