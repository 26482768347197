import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fab, faLinkedin, faGithub } from '@fortawesome/free-brands-svg-icons';
import { fas, faFileAlt, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import AOS from 'aos';
import 'aos/dist/aos.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';

// Pages
import Home from './pages/home.js';
import MyResume from './pages/resume.js';
import HomeTemps from './pages/temps.js';

// Components
import MyNavbar from './components/navbar.js';

// Add FontAwesome icons to library
library.add(fab, fas, faLinkedin, faGithub, faFileAlt, faEnvelope);

function App() {
  useEffect(() => {
    // Initialize AOS animation library
    AOS.init({
      duration: 1000,
      once: true,
      mirror: false,
      offset: 100,
    });
  }, []);

  return (
    <Router>
      <div className="App">
        <MyNavbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/resume" element={<MyResume />} />
          <Route path="/graphs" element={<HomeTemps />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
