import React from 'react';
import { Container, Row, Col, Image, Button, Badge } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload, faBuilding, faGraduationCap, faCalendarAlt, faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import professionalPhoto from '../images/professionalPhoto.jpg';

// Custom CSS styles
const styles = {
  companyLink: {
    textDecoration: 'none',
    color: 'inherit'
  }
};

const MyResume = () => {
  // Skills data
  const skills = {
    product: [
      "Strategic product planning", "Product ideation through launch", "Go-to-Market Strategy",
      "User-centric design", "Team leadership", "Stakeholder collaboration", 
      "Product metrics and analytics", "Agile methodologies"
    ],
    technical: [
      "Healthcare Informatics", "Clinical Systems", "Data Analysis", "HIPAA Compliance",
      "Electronic Health Records", "Pharmacy Systems", "Healthcare IT", "System Integration"
    ],
    leadership: [
      "Cross-functional Team Leadership", "Strategic Planning", "Client Relationship Management",
      "Continuous learning", "Process Improvement", "Mentoring", "Project Management"
    ]
  };

  return (
    <div className="resume-section" id="resume">
      <Container>
        {/* Resume Header */}
        <div className="resume-header">
          <Row className="justify-content-center">
            <Col md={4} lg={3}>
              <Image 
                src={professionalPhoto} 
                className="resume-photo img-fluid" 
                alt="Professional Headshot of Caleb Dunn" 
              />
            </Col>
            <Col md={8} lg={9} className="text-center text-md-start">
              <h1 className="resume-name">Caleb Dunn, PharmD</h1>
              <p className="resume-title">Senior Product Manager | Healthcare Informatics | Clinical Pharmacy</p>
              <div className="d-flex flex-wrap justify-content-center justify-content-md-start mb-4">
                <span className="me-4 mb-2">
                  <FontAwesomeIcon icon={faMapMarkerAlt} className="me-2 text-secondary" />
                  Tulsa, Oklahoma
                </span>
                <span className="me-4 mb-2">
                  <FontAwesomeIcon icon={faBuilding} className="me-2 text-secondary" />
                  <a href="https://www.drfirst.com" target="_blank" rel="noopener noreferrer">DrFirst</a>
                </span>
                <span className="mb-2">
                  <FontAwesomeIcon icon={faCalendarAlt} className="me-2 text-secondary" />
                  Available for new opportunities
                </span>
              </div>
              <Button variant="primary" className="me-3" href="/resume.pdf" target="_blank">
                <FontAwesomeIcon icon={faDownload} className="me-2" /> Download Resume
              </Button>
            </Col>
          </Row>
        </div>

        <Row className="mt-5">
          {/* Main Content */}
          <Col lg={8}>
            {/* Professional Summary */}
            <section className="mb-5">
              <h2 className="resume-section-title">Professional Summary</h2>
              <p>
                Product Management Leader with over 10 years in healthcare technology, focusing on creating and growing products that solve real problems. I blend clinical knowledge with technical skills to build solutions that work for users and drive business results. I enjoy bringing teams together across disciplines, helping them collaborate to tackle complex healthcare challenges. My approach centers on understanding market needs and turning them into products that make a difference for patients and providers while growing revenue.
              </p>
            </section>

            {/* Professional Experience */}
            <section className="mb-5">
              <h2 className="resume-section-title">Professional Experience</h2>
              
              {/* Job 1 */}
              <article className="resume-item" itemScope itemType="https://schema.org/WorkPosition">
                <div className="resume-item-header d-flex flex-column flex-md-row justify-content-between align-items-md-center mb-2">
                  <div>
                    <h3 className="resume-item-title fw-bold text-start" itemProp="name">Senior Product Manager</h3>
                    <div className="d-flex align-items-center mb-1">
                      <FontAwesomeIcon icon={faBuilding} className="me-2 text-primary" />
                      <p className="resume-item-subtitle mb-0" itemProp="worksFor">
                        <a href="https://www.drfirst.com" target="_blank" rel="noopener noreferrer" style={styles.companyLink}>DrFirst</a>, Tulsa, OK
                      </p>
                    </div>
                  </div>
                  <div className="resume-timeline">
                    <span className="badge bg-primary px-3 py-2 rounded-pill" itemProp="validFrom" content="2022-06">
                      <FontAwesomeIcon icon={faCalendarAlt} className="me-2" />
                      June 2022 - Present
                    </span>
                  </div>
                </div>
                <ul className="resume-item-description text-start" itemProp="description">
                  <li>Led the creation of Gadget, a first-in-market healthcare technology product with a six-member team. Built a collaborative team culture that delivered the full product scope on schedule.</li>
                  <li>Developed and executed a market-driven launch strategy based on customer needs research. Worked closely with Sales, Marketing, and leadership teams to achieve a 35% higher adoption rate than projected.</li>
                  <li>Redesigned the alerts experience using direct user feedback, cutting workflow steps by half and significantly increasing user satisfaction within months.</li>
                  <li>Saved a $2 million annual client relationship by finding creative workflow improvements with existing tools, which also generated an extra $250,000 in yearly revenue.</li>
                </ul>
                <div className="resume-item-skills d-flex flex-wrap gap-2 mt-3">
                  <Badge pill bg="primary" className="skill-tag-premium">Product Strategy</Badge>
                  <Badge pill bg="primary" className="skill-tag-premium">Go-to-Market Leadership</Badge>
                  <Badge pill bg="light" text="dark" className="skill-tag-premium">Healthcare Technology Innovation</Badge>
                  <Badge pill bg="light" text="dark" className="skill-tag">Cross-functional Team Leadership</Badge>
                  <Badge pill bg="light" text="dark" className="skill-tag">Product Development Lifecycle</Badge>
                  <Badge pill bg="light" text="dark" className="skill-tag">Revenue Growth</Badge>
                </div>
              </article>
              
              {/* Job 2 */}
              <article className="resume-item mt-4" itemScope itemType="https://schema.org/WorkPosition">
                <div className="resume-item-header d-flex flex-column flex-md-row justify-content-between align-items-md-center mb-2">
                  <div>
                    <h3 className="resume-item-title fw-bold text-start" itemProp="name">Product Manager</h3>
                    <div className="d-flex align-items-center mb-1">
                      <FontAwesomeIcon icon={faBuilding} className="me-2 text-primary" />
                      <p className="resume-item-subtitle mb-0" itemProp="worksFor">
                        <a href="https://www.drfirst.com" target="_blank" rel="noopener noreferrer" style={styles.companyLink}>DrFirst</a>, Tulsa, OK
                      </p>
                    </div>
                  </div>
                  <div className="resume-timeline">
                    <span className="badge bg-primary px-3 py-2 rounded-pill" itemProp="validFrom" content="2021-06">
                      <FontAwesomeIcon icon={faCalendarAlt} className="me-2" />
                      June 2021 - June 2022
                    </span>
                  </div>
                </div>
                <ul className="resume-item-description text-start" itemProp="description">
                  <li>Found and implemented quick-win development opportunities that improved quality scores by 15% and unlocked $1.2 million in previously untapped revenue.</li>
                  <li>Contributed healthcare regulatory insights during planning sessions to shape product strategies that met both compliance requirements and market needs.</li>
                  <li>Connected Development, QA, and Client Success teams to deliver solutions that both delighted clients and addressed key market challenges.</li>
                </ul>
                <div className="resume-item-skills d-flex flex-wrap gap-2 mt-3">
                  <Badge pill bg="primary" className="skill-tag-premium">Strategic Product Planning</Badge>
                  <Badge pill bg="primary" className="skill-tag-premium">Healthcare Regulatory Expertise</Badge>
                  <Badge pill bg="light" text="dark" className="skill-tag-premium">Revenue Optimization</Badge>
                  <Badge pill bg="light" text="dark" className="skill-tag-premium">Client Success Partnerships</Badge>
                  <Badge pill bg="light" text="dark" className="skill-tag-premium">Product-Market Fit</Badge>
                  <Badge pill bg="light" text="dark" className="skill-tag-premium">User Experience Design</Badge>
                </div>
              </article>
              
              {/* Job 3 */}
              <article className="resume-item mt-4" itemScope itemType="https://schema.org/WorkPosition">
                <div className="resume-item-header d-flex flex-column flex-md-row justify-content-between align-items-md-center mb-2">
                  <div>
                    <h3 className="resume-item-title fw-bold text-start" itemProp="name">Clinical Product Analyst</h3>
                    <div className="d-flex align-items-center mb-1">
                      <FontAwesomeIcon icon={faBuilding} className="me-2 text-primary" />
                      <p className="resume-item-subtitle mb-0" itemProp="worksFor">
                        <a href="https://www.drfirst.com" target="_blank" rel="noopener noreferrer" style={styles.companyLink}>DrFirst</a>, Tulsa, OK
                      </p>
                    </div>
                  </div>
                  <div className="resume-timeline">
                    <span className="badge bg-primary px-3 py-2 rounded-pill" itemProp="validFrom" content="2019-09">
                      <FontAwesomeIcon icon={faCalendarAlt} className="me-2" />
                      September 2019 - June 2021
                    </span>
                  </div>
                </div>
                <ul className="resume-item-description text-start" itemProp="description">
                  <li>Built an e-prescribing backend aggregator service with a diverse team, guiding the project from concept to completion and cutting prescription processing time by 40%.</li>
                  <li>Created real-time monitoring systems that improved product reliability by 25% and reduced system downtime by 30%.</li>
                  <li>Kept projects moving forward during technical challenges while maintaining 95% stakeholder satisfaction throughout the implementation process.</li>
                </ul>
                <div className="resume-item-skills d-flex flex-wrap gap-2 mt-3">
                  <Badge pill bg="primary" className="skill-tag-premium">Product Lifecycle Management</Badge>
                  <Badge pill bg="primary" className="skill-tag-premium">Clinical Workflow Optimization</Badge>
                  <Badge pill bg="light" text="dark" className="skill-tag-premium">Stakeholder Management</Badge>
                  <Badge pill bg="light" text="dark" className="skill-tag-premium">Technical Implementation Leadership</Badge>
                  <Badge pill bg="light" text="dark" className="skill-tag-premium">Healthcare Systems Integration</Badge>
                  <Badge pill bg="light" text="dark" className="skill-tag-premium">Performance Analytics</Badge>
                </div>
              </article>

              {/* Job 4 */}
              <article className="resume-item mt-4" itemScope itemType="https://schema.org/WorkPosition">
                <div className="resume-item-header d-flex flex-column flex-md-row justify-content-between align-items-md-center mb-2">
                  <div>
                    <h3 className="resume-item-title fw-bold text-start" itemProp="name">Pharmacy Informaticist</h3>
                    <div className="d-flex align-items-center mb-1">
                      <FontAwesomeIcon icon={faBuilding} className="me-2 text-primary" />
                      <p className="resume-item-subtitle mb-0" itemProp="worksFor">
                        <a href="https://healthcare.ascension.org/" target="_blank" rel="noopener noreferrer" style={styles.companyLink}>Ascension Technologies</a>, Tulsa, OK
                      </p>
                    </div>
                  </div>
                  <div className="resume-timeline">
                    <span className="badge bg-primary px-3 py-2 rounded-pill" itemProp="validFrom" content="2016-03">
                      <FontAwesomeIcon icon={faCalendarAlt} className="me-2" />
                      March 2016 - September 2019
                    </span>
                  </div>
                </div>
                <ul className="resume-item-description text-start" itemProp="description">
                  <li>Created and maintained pharmacy system standards for 15 hospitals that kept EMR systems running smoothly with 99.8% reliability during CPOE implementation.</li>
                  <li>Solved system problems with technical fixes that improved operations and cut medication errors by 22%.</li>
                  <li>Worked alongside pharmacy teams to build information solutions that made workflows 35% more efficient and reduced medication turn-around time by 15 minutes.</li>
                </ul>
                <div className="resume-item-skills d-flex flex-wrap gap-2 mt-3">
                  <Badge pill bg="primary" className="skill-tag-premium">Enterprise Healthcare Systems</Badge>
                  <Badge pill bg="primary" className="skill-tag-premium">Cross-functional Collaboration</Badge>
                  <Badge pill bg="light" text="dark" className="skill-tag-premium">Operational Excellence</Badge>
                  <Badge pill bg="light" text="dark" className="skill-tag-premium">Technical Solution Design</Badge>
                  <Badge pill bg="light" text="dark" className="skill-tag-premium">Process Improvement</Badge>
                  <Badge pill bg="light" text="dark" className="skill-tag-premium">Healthcare Data Management</Badge>
                </div>
              </article>

              {/* Job 5 */}
              <article className="resume-item mt-4" itemScope itemType="https://schema.org/WorkPosition">
                <div className="resume-item-header d-flex flex-column flex-md-row justify-content-between align-items-md-center mb-2">
                  <div>
                    <h3 className="resume-item-title fw-bold text-start" itemProp="name">Pharmacist</h3>
                    <div className="d-flex align-items-center mb-1">
                      <FontAwesomeIcon icon={faBuilding} className="me-2 text-primary" />
                      <p className="resume-item-subtitle mb-0" itemProp="worksFor">
                        <a href="https://saintfrancis.drfirst.com" target="_blank" rel="noopener noreferrer" style={styles.companyLink}>Saint Francis Children's Hospital</a>, Tulsa, OK
                      </p>
                    </div>
                  </div>
                  <div className="resume-timeline">
                    <span className="badge bg-primary px-3 py-2 rounded-pill" itemProp="validFrom" content="2014-12">
                      <FontAwesomeIcon icon={faCalendarAlt} className="me-2" />
                      December 2014 - April 2021
                    </span>
                  </div>
                </div>
                <ul className="resume-item-description text-start" itemProp="description">
                  <li>Checked more than 200 pediatric medication orders each day for correct dosing and clinical appropriateness, achieving zero preventable adverse drug events for 3 years straight.</li>
                  <li>Ran daily pharmacy operations and guided support staff while ensuring full compliance with all pharmacy policies and regulations.</li>
                  <li>Offered expert advice on complex pediatric cases, helping medical teams with evidence-based medication decisions that improved outcomes for critically ill children.</li>
                </ul>
                <div className="resume-item-skills d-flex flex-wrap gap-2 mt-3">
                  <Badge pill bg="primary" className="skill-tag-premium">Clinical Decision Support</Badge>
                  <Badge pill bg="primary" className="skill-tag-premium">Medication Safety</Badge>
                  <Badge pill bg="light" text="dark" className="skill-tag-premium">Clinical Pharmacokinetics</Badge>
                  <Badge pill bg="light" text="dark" className="skill-tag-premium">Regulatory Compliance</Badge>
                  <Badge pill bg="light" text="dark" className="skill-tag-premium">Patient Care Optimization</Badge>
                  <Badge pill bg="light" text="dark" className="skill-tag-premium">Clinical Education</Badge>
                </div>
              </article>
            </section>
          </Col>

          {/* Sidebar */}
          <Col lg={4}>
            {/* Education */}
            <section className="mb-5">
              <h2 className="resume-section-title">Education</h2>
              
              <div className="resume-item">
                <h3 className="education-degree">M.S. in Healthcare Informatics and Information Management</h3>
                <p className="education-institution">Southwestern Oklahoma State University</p>
                <p className="education-date">Graduated: Spring 2021</p>
              </div>
              
              <div className="resume-item">
                <h3 className="education-degree">ASHP-Accredited PGY1 Pharmacy Practice Residency</h3>
                <p className="education-institution">Wesley Medical Center</p>
                <p className="education-date">Completed: Summer 2014</p>
              </div>
              
              <div className="resume-item">
                <h3 className="education-degree">Doctor of Pharmacy (Pharm.D.)</h3>
                <p className="education-institution">Drake University</p>
                <p className="education-date">Graduated: Spring 2012</p>
              </div>
            </section>

            {/* Professional Licensure */}
            <section className="mb-5">
              <h2 className="resume-section-title">Professional Licensure</h2>
              
              <div className="resume-item">
                <p>Registered Pharmacist, #1-15889<br />Kansas State Board of Pharmacy<br />2013 - Present</p>
              </div>
              
              <div className="resume-item">
                <p>Registered Pharmacist, #15233<br />Oklahoma State Board of Pharmacy<br />2012 - Present</p>
              </div>
            </section>

            {/* Skills */}
            <section className="mb-5">
              <h2 className="resume-section-title">Skills</h2>
              
              <div className="mb-4">
                <h3 className="h5 mb-3">Product Management</h3>
                <div className="d-flex flex-wrap gap-2">
                  {skills.product.map((skill, index) => (
                    <Badge key={index} bg="light" text="dark" className="skill-tag">{skill}</Badge>
                  ))}
                </div>
              </div>
              
              <div className="mb-4">
                <h3 className="h5 mb-3">Technical</h3>
                <div className="d-flex flex-wrap gap-2">
                  {skills.technical.map((skill, index) => (
                    <Badge key={index} bg="light" text="dark" className="skill-tag">{skill}</Badge>
                  ))}
                </div>
              </div>
              
              <div>
                <h3 className="h5 mb-3">Leadership</h3>
                <div className="d-flex flex-wrap gap-2">
                  {skills.leadership.map((skill, index) => (
                    <Badge key={index} bg="light" text="dark" className="skill-tag">{skill}</Badge>
                  ))}
                </div>
              </div>
            </section>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default MyResume;
